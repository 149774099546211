import React, { Component, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
import axios from 'axios';


// Dynamic imports
const VodacomLogo = dynamic(() => import('../../../../public/assets/logo/VodacomLogo.js'));
const VodacomBusinessLogo = dynamic(() => import('../../../../public/assets/logo/vodacom-business-logo.js'));
const Navigation = dynamic(() => import('../Navigation'));
const SearchComponent = dynamic(() => import('../SearchComponent'));


const Header = (props) => {
  const s = require('./header.module.scss');
  const [opened, setOpened] = useState(false);
  const [navigation, setNavigation] = useState();

  useEffect(async () => {
      const response = await axios.get(`/api/navigation`);
      setNavigation(response.data)
  }, []);
  
  const { path, toggleSearch, isMenuOpen, terms } = props;

  return (
      <div className={s.Header}>
        <div className={s.HeaderContent}>
          <Link href="/" passHref>
            <a className={s.Logo} aria-label="Vodacom logo">
              {path.includes('business') || path.includes('category/vodacom-solutions')
              || path.includes('category/opinion') || path.includes('category/devices')
              || path.includes('category/case-studies') || path.includes('category/catelogues')
              || path.includes('category/sme-solutions') ? <VodacomBusinessLogo style={{ maxWidth: '150px' }} /> :
              <VodacomLogo style={{ width: '252px', height: '29px' }} />}
            </a>
          </Link>
          <div className={s.HeaderContainer}>
            <SearchComponent
              toggleSearch={toggleSearch}
              isMenuOpen={isMenuOpen}
              terms={terms}
            />
            <Navigation
              toggleMenu={() => setOpened(!opened)}
              opened={opened}
              path={path}
              navigation={navigation}
            />

          </div>
        </div>
      </div>
    );
}

Header.propTypes = {
  path: PropTypes.string,
  toggleSearch: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  terms: PropTypes.string,
  navigation: PropTypes.object
};

export default Header;